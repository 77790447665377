import styled from '@mui/material/styles/styled'

export const WrapperListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  gap: theme.spacing(6),
  flexDirection: 'column',
  padding: `${theme.spacing(6)} ${theme.spacing(10.5)}`,

  [theme.breakpoints.up('sm')]: {
    padding: `0 ${theme.spacing(12)} ${theme.spacing(6)} ${theme.spacing(6)}`,
  },
}))

export const ContentListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Content',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: theme.spacing(2),
  rowGap: theme.spacing(6),

  [theme.breakpoints.up('sm')]: {
    maxWidth: 360,
  }
}))

export const ContentCtaListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Cta',
})({
})

export const TitleListOfBrandIcon = styled('div', {
  name: 'ListOfBrandIcon',
  slot: 'Title',
})(({ theme }) => ({
   fontSize: theme.typography.body2.fontSize,
   fontWeight: 'bold',
}))