import styled from '@mui/material/styles/styled'

export const CtaContainer = styled('div', {
  name: 'CtaMegaMenu',
  slot: 'Container',
})(({ theme }) => ({
  'a': {
    marginLeft: 0,
    marginRight: 0,
    [theme.breakpoints.down('sm')]: {
      margin: 0
    }
  }
}))
