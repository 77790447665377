import styled from '@mui/material/styles/styled'

export const WrapperCategoriesWithIcon = styled('div', {
  name: 'CategoriesWithIcon',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(6)}`,
  },
  padding: `${theme.spacing(6)} ${theme.spacing(6)} 0`,
  fontSize: 14,
  color: theme.palette.text.dark.primary,
}))

export const TitleCategories = styled('div', {
  name: 'CategoriesWithIcon',
  slot: 'Title',
})(() => ({
  fontWeight: 'bold',
}))

export const ContentIconsCategories = styled('div', {
  name: 'CategoriesWithIcon ',
  slot: 'Content',
})(({ theme }) => ({
  padding: `${theme.spacing(6)} 0`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}))

export const IconsLinkCategories = styled('div', {
  name: 'CategoriesWithIcon',
  slot: 'IconLinks',
})(({ theme }) => ({
  display: 'inherit',
  gap: theme.spacing(4),
  alignItems: 'center',
}))

export const ContentCtaCategories = styled('div', {
  name: 'CategoriesWithIcon',
  slot: 'Cta',
})(() => ({
  marginTop: 'auto',
}))
