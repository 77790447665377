import React from 'react'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import styled from '@mui/material/styles/styled'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export const ContentCmsIcon = styled('div')(({ theme }) => ({
  height: theme.spacing(8),
  width: theme.spacing(8),
}))

export const ContentArrowIcon = styled('div')(({ theme }) => ({
  color: theme.palette.background.dark.secondary,
  height: theme.spacing(6.5),
}))

export const IconWithTextArrow: React.FC<{ teasableItem: ILXTeaser }> = ({ teasableItem }) => {
  const icon = teasableItem.teaserIcon || ''
  const text = teasableItem.teaserText1.replace(/<.*?>/gi, '') || ''

  return (
    <>
      <ContentCmsIcon>
        <CmsIcon teaserIcon={icon} size={32} />
      </ContentCmsIcon>
      <div>{text}</div>
      <ContentArrowIcon>
        <SVGIcon library="arrow" name="arrow-slim-down" size={32} />
      </ContentArrowIcon>
    </>
  )
}
