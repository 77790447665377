import React from 'react'
import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import { useDispatch } from 'react-redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '../../features/ui/action'
import { Button, StyledAnchor, StyledLinkAsButton } from '../UI'
import { CtaContainer } from './CtaMegaMenu.style'
import { useExternalsActions } from '@hooks/useExternalsAction'
import { getCtaStyle } from '@components/Cms/CmsComponents-CSS/CmsCta'

export interface IProps {
  actionSettings: ITeaserCallToAction
  dataElementId: string
  displayAsButton?: boolean
  disableMarginBottom?: boolean
}

const CtaMegaMenu: React.FC<IProps> = ({
  actionSettings,
  dataElementId,
  displayAsButton = true,
  disableMarginBottom = false,
}) => {
  const dispatch = useDispatch()
  const { basePath } = useStoreIdentity()
  const actions = useExternalsActions()

  const ctaEnabled = actionSettings?.callToActionEnabled || false
  const text = actionSettings?.callToActionText || ''
  const style = actionSettings?.style || ''
  const { color } = getCtaStyle(style)

  const isExternalLink = actionSettings?.target?.type === 'CMExternalLink'
  const formattedUrl = actionSettings?.target?.formattedUrl || ''

  const link = isExternalLink ? actionSettings?.target?.url || '' : `${basePath}${formattedUrl ?? ''}`

  if (!ctaEnabled) return null

  if (actionSettings.target?.type === 'Action') {
    const action = actions[actionSettings.target.idAction]

    return (
      <Button variant={color} onClick={action} style={{ marginBottom: disableMarginBottom ? '0px' : '75px' }}>
        {text}
      </Button>
    )
  }

  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))
  }
  const getLinkElement = displayAsButton => {
    if (displayAsButton) {
      return (
        <StyledLinkAsButton dataElementId={dataElementId} href={link} external={isExternalLink}>
          {text}
        </StyledLinkAsButton>
      )
    }
    return (
      <StyledAnchor href={link} sx={{ fontSize: '1rem' }} external={isExternalLink}>
        {text}
      </StyledAnchor>
    )
  }

  return (
    <div onClick={closeMegaMenu} style={{ marginBottom: disableMarginBottom ? '0px' : '75px' }}>
      <CtaContainer>{getLinkElement(displayAsButton)}</CtaContainer>
    </div>
  )
}

export default CtaMegaMenu
