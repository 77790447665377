import React from 'react'
import { useDispatch } from 'react-redux'
import { Anchor } from '@components/UI-CSS/Anchor'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '@features/ui/action'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { CmsAnchorMegaMenuProps } from './types/CmsAnchorMegaMenu.types'
import styles from './styles/CmsAnchorMegaMenu.module.scss'

export const CmsAnchorMegaMenu: React.FC<CmsAnchorMegaMenuProps> = ({ description, elementId, href, children }) => {
  const dispatch = useDispatch()
  const { basePath } = useStoreIdentity()
  const link = `${basePath}${href}`

  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))
  }

  return (
    <div onClick={closeMegaMenu}>
      <Anchor
        className={styles.anchor}
        data-description={description}
        data-element-id={elementId || 'X_X_'}
        href={link}
      >
        {children}
      </Anchor>
    </div>
  )
}
