import styled from '@mui/material/styles/styled'

export const WrapperBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Wrapper',
})(({ theme }) => ({
  height: '100%',
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.text.dark.primary,
  padding: `0 ${theme.spacing(6)} ${theme.spacing(6)} ${theme.spacing(6)}`,
}))

export const TopContentBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'ContentTop',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const BottomContentBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'ContentBottom',
})(({ theme }) => ({
  marginTop: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const TitleBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Title',
})<{ fontSize?: number; fontWeight?: string }>(
  ({ fontSize = 12, fontWeight = 'normal' }) => ({
    fontSize,
    fontWeight,
  })
)

export const TextBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Text',
})(() => ({}))

export const FormBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Form',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  marginTop: theme.spacing(4),
}))

export const IconTextBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'IconText',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))

export const CtaBookAnAppointment = styled('div', {
  name: 'BookAnAppointmentSearchBox',
  slot: 'Cta',
})(({ theme }) => ({
  marginTop: theme.spacing(3.5),
}))
