import React from 'react'
import { IPlacement, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import {
  CloseIconButton,
  ContentBigMenuBanner,
  CtaMenuBanner,
  MediaWrapper,
  TextMenuBanner,
  TitleMenuBanner,
  WrapperBigMenuBanner,
} from './BigMegaBanner.style'
import CtaMegaMenu from '../../CtaMegaMenu'
import { useDispatch } from 'react-redux'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '../../../features/ui/action'
import useBreakpoints from '../../../hooks/useBreakpoints'
import MediaOverlap from '../../CmsModules/MediaOverlapV2'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const BigMenuBanner: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const { isDesktop } = useBreakpoints()
  const dispatch = useDispatch()
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const teaserItem = placement?.items?.find(isLXTeaser)

  if (!teaserItem) return null
  const title = teaserItem.teaserTitle1 || ''
  const text = teaserItem.teaserText1.replace(/<.*?>/gi, '') || ''
  const teaserLXCallToActionSettings = teaserItem.teaserLXCallToActionSettings || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
    dispatch(setHamburgerMenuOpenStatus(false))
  }

  return (
    <WrapperBigMenuBanner {...metaData}>
      {isDesktop && (
        <CloseIconButton onClick={closeMegaMenu} data-analytics_available_call="0">
          <SVGIcon library="close" name="close" />
        </CloseIconButton>
      )}
      <MediaWrapper onClick={closeMegaMenu}>
        <MediaOverlap viewType={'big-menu-banner'} crop={'BIG_MENU_BANNER'} placement={placement} fullHeight />
      </MediaWrapper>
      <ContentBigMenuBanner>
        <TitleMenuBanner>{title}</TitleMenuBanner>
        <TextMenuBanner>{text}</TextMenuBanner>
        <CtaMenuBanner>
          {teaserLXCallToActionSettings.map((actionSettings, index) => (
            <CtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              dataElementId={`X_X_Big_Menu_Banner_Placement_Banner_${actionSettings.callToActionText?.replaceAll(
                ' ',
                '_'
              )}_CTA${index}`}
              actionSettings={actionSettings}
              disableMarginBottom
            />
          ))}
        </CtaMenuBanner>
      </ContentBigMenuBanner>
    </WrapperBigMenuBanner>
  )
}

export default BigMenuBanner
