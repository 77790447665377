import styled from '@mui/material/styles/styled'

export const WrapperListOfBrands = styled('div', {
  name: 'ListOfBrands',
  slot: 'Wrapper',
})(({ theme }) => ({
  padding: `0 ${theme.spacing(6)} ${theme.spacing(6)}`,
  color: theme.palette.text.dark.primary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
  fontSize: 14,
}))

export const TitleListOfBrands = styled('div', {
  name: 'ListOfBrands',
  slot: 'Title',
})(() => ({
  fontWeight: 'bold',
}))

export const ContentListOfBrands = styled('div', {
  name: 'ListOfBrands',
  slot: 'ContentList',
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}))
