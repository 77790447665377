import React from 'react'
import { IPlacement, isCMCollection, isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import {
  ContentBannerWithIcons,
  ContentCtaBannersWithIcons,
  TitleBannersWithIcons,
  WrapperBannersWithIcons,
} from './BannersWithIcons.style'
import useBreakpoints from '../../../hooks/useBreakpoints'
import CtaMegaMenu from '../../CtaMegaMenu'
import { IconWithTextArrow } from './components/IconWithTextArrow'

const BannersWithIcons: React.FC<{ placement: IPlacement }> = ({
  placement,
}) => {
  const { isDesktop } = useBreakpoints()
  const viewType = placement?.viewtype || ''
  const placementName = placement?.name || ''
  const collectionItem = placement?.items?.find(isCMCollection)

  if (!collectionItem) return null
  const title = collectionItem.collectionTitle || ''
  const teasableItems = collectionItem.teasableItems?.filter(isLXTeaser) || []
  const itemLXCallToActionSettings =
    collectionItem.teaserLXCallToActionSettings || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <WrapperBannersWithIcons {...metaData}>
      <TitleBannersWithIcons>{title}</TitleBannersWithIcons>
      <ContentBannerWithIcons>
        {teasableItems.map((teasableItem) => (
          <React.Fragment key={teasableItem.id}>
            <IconWithTextArrow teasableItem={teasableItem} />
          </React.Fragment>
        ))}
      </ContentBannerWithIcons>
      {isDesktop && (
        <ContentCtaBannersWithIcons>
          {itemLXCallToActionSettings.map((actionSettings, index) => (
            <CtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              dataElementId={`X_X_Banners_With_Icons_Placement_Banner_${actionSettings.callToActionText?.replaceAll(' ','_')}_CTA${index}`}
              actionSettings={actionSettings}
            />
          ))}
        </ContentCtaBannersWithIcons>
      )}
    </WrapperBannersWithIcons>
  )
}

export default BannersWithIcons
