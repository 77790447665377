import React, { PropsWithChildren, useEffect, useRef, useState, FC } from 'react'
import { styled, useTheme } from '@mui/material/styles'

import { IconButton } from '..'
import { debounce } from 'lodash-es'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

const ScrollContainer = styled('div', {
  name: 'ScrollContainer',
  slot: 'root',
})(({ theme }) => ({
  overflowY: 'auto',
  height: '100%',
  scrollbarWidth: 'none',
  backgroundColor: theme.palette.background.light.quinary, //'#F1F2F2',
  msOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}))

const ScrollControlsContainer = styled('div', {
  name: 'ScrollContainer',
  slot: 'controls',
})(({ theme }) => ({
  overflowY: 'auto',
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: 100,
  gap: theme.spacing(2),
  background: 'linear-gradient(to bottom, transparent, #f6f6f6 60%)',
  pointerEvents: 'none',
  opacity: '0.75',
  button: {
    width: 16,
    padding: `0 ${theme.spacing(5)}`,
    color: theme.palette.text.dark.primary,
    transition: '0.2s ease-in-out',
  },
  'button:hover': {
    opacity: 0.7,
  },
  'button[aria-hidden=true]': {
    opacity: 0,
    cursor: 'pointer',
  },
}))

const SCROLL_STEP_SIZE = 200

const ScrollableContainerWithArrows: FC<PropsWithChildren> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const theme = useTheme()

  const [isScrollEnabled, setScrollEnabled] = useState<boolean>(true)
  const [scrollControlsVisibilityStatus, setScrollControlsVisibilityStatus] = useState<'up' | 'down' | 'both'>('down')

  const calcScrollControlsVisibilityStatus = (scrollPosition: number) => {
    const containerElement = containerRef.current!

    if (Math.ceil(scrollPosition + containerElement.clientHeight) >= containerElement.scrollHeight) {
      setScrollControlsVisibilityStatus('up')
    } else if (scrollPosition === 0) {
      setScrollControlsVisibilityStatus('down')
    } else {
      setScrollControlsVisibilityStatus('both')
    }
  }

  const handleScrollChange = (action: 'up' | 'down') => {
    const containerElement = containerRef.current!

    const _newScrollPosition = containerElement.scrollTop + (action === 'up' ? SCROLL_STEP_SIZE * -1 : SCROLL_STEP_SIZE)

    const newScrollPosition = _newScrollPosition < 0 ? 0 : _newScrollPosition

    containerElement.scroll({
      behavior: 'smooth',
      top: newScrollPosition,
    })

    calcScrollControlsVisibilityStatus(newScrollPosition)
  }

  useEffect(() => {
    const containerElement = containerRef.current!
    const { top } = containerElement.getBoundingClientRect()

    setScrollEnabled(containerElement.scrollHeight + top > window.innerHeight)
  }, [])

  useEffect(() => {
    const containerElement = containerRef.current!
    if (!containerElement) {
      return
    }
    const debouncedScrollListener = debounce(() => {
      calcScrollControlsVisibilityStatus(containerElement.scrollTop)
    }, 200)

    containerElement.addEventListener('scroll', debouncedScrollListener, false)

    return () => {
      containerElement.removeEventListener('scroll', debouncedScrollListener, false)
    }
  }, [window.innerHeight])

  useEffect(() => {
    const containerElement = containerRef.current!

    if (containerElement) {
      containerElement.scroll({ top: 0 })
      calcScrollControlsVisibilityStatus(0)
    }
  }, [children])

  return isScrollEnabled ? (
    <ScrollContainer ref={containerRef}>
      {children}

      <ScrollControlsContainer>
        <IconButton aria-hidden={scrollControlsVisibilityStatus === 'down'} onClick={() => handleScrollChange('up')}>
          <SVGIcon library="arrow" name="arrow-up" color={theme.palette.text.dark.primary} />
        </IconButton>
        <IconButton aria-hidden={scrollControlsVisibilityStatus === 'up'} onClick={() => handleScrollChange('down')}>
          <SVGIcon library="arrow" name="arrow-down" />
        </IconButton>
      </ScrollControlsContainer>
    </ScrollContainer>
  ) : (
    <>{children}</>
  )
}

export default ScrollableContainerWithArrows
