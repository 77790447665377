import React from 'react'
import {
  IPlacement,
  isCMCollection,
  isCMExternalChannel,
  isLXTeaser,
} from '@typesApp/cmsPlacement/Placement'
import {
  ContentCtaListOfBrandIcon,
  ContentListOfBrandIcon,
  TitleListOfBrandIcon,
  WrapperListOfBrandIcon,
} from './ListOfBrandIcon.style'
import CtaMegaMenu from '../../CtaMegaMenu'
import useBreakpoints from '../../../hooks/useBreakpoints'
import BrandIconLink from './components/BrandIconLink'

const ListOfBrandIcon: React.FC<{ placement: IPlacement }> = ({
  placement,
}) => {
  const { isDesktop } = useBreakpoints()
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || ''
  const collectionItem = placement?.items?.find(isCMCollection)
  const teaserItem = placement?.items?.find(isLXTeaser)

  if (!collectionItem) return null
  const teaserLXCallToActionSettings =
    teaserItem?.teaserLXCallToActionSettings || []
  const teasableItems =
    collectionItem.teasableItems?.filter(isCMExternalChannel) || []
  const title = collectionItem.collectionTitle

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }
  
  return (
    <WrapperListOfBrandIcon {...metaData}>
      <TitleListOfBrandIcon>{title}</TitleListOfBrandIcon>
      {isDesktop && (
        <ContentCtaListOfBrandIcon>
          {teaserLXCallToActionSettings?.map((actionSettings, index) => (
            <CtaMegaMenu
              key={`cms-content__${viewType}--cta-${index}`}
              dataElementId={`X_X_List_Of_Brands_Placement_Banner_${actionSettings.callToActionText?.replaceAll(' ','_')}_CTA${index}`}
              actionSettings={actionSettings}
              displayAsButton={false}
              disableMarginBottom={true}
            />
          ))}
        </ContentCtaListOfBrandIcon>
      )}
      <ContentListOfBrandIcon>
        {teasableItems.map((teasableItem) => (
          <React.Fragment key={teasableItem.id}>
            <BrandIconLink teasableItem={teasableItem} width={'90%'} />
          </React.Fragment>
        ))}
      </ContentListOfBrandIcon>
    </WrapperListOfBrandIcon>
  )
}

export default ListOfBrandIcon
