import styled from '@mui/material/styles/styled'
import { Z_INDEX_LV3 } from '../../../constants/ui'
import { IconButton } from '../../UI'

export const WrapperBigMenuBanner = styled('div', {
  name: 'BigMenuBanner',
  slot: 'Wrapper',
})(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
}))

export const ContentBigMenuBanner = styled('div', {
  name: 'BigMenuBanner',
  slot: 'Content',
})(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  backgroundColor: theme.palette.background.light.primary,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
  padding: theme.spacing(6),
  color: theme.palette.text.dark.primary,
  marginTop: 'auto',
  width: '100%',
}))

export const TitleMenuBanner = styled('div', {
  name: 'BigMenuBanner',
  slot: 'Title',
})({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: 14,
})

export const TextMenuBanner = styled('div', {
  name: 'BigMenuBanner',
  slot: 'Text',
})({})

export const CtaMenuBanner = styled('div', {
  name: 'BigMenuBanner',
  slot: 'Cta',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4),
  },

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    marginTop: 'auto',
  },
}))

export const CloseIconButton = styled(IconButton, {
  name: 'BigMenuBanner ',
  slot: 'CloseButton',
})(({ theme }) => ({
  width: 56,
  height: 56,
  position: 'absolute',
  zIndex: Z_INDEX_LV3,
  right: 0,
  color: theme.palette.background.dark.primary,
  backgroundColor: theme.palette.background.light.primary,
  padding: theme.spacing(4),
  borderRadius: 0,

  '&:hover': {
    backgroundColor: theme.palette.background.light.primary,
  },
}))

export const MediaWrapper = styled('div', {
  name: 'BigMenuBanner ',
  slot: 'MediaWrapper',
})(() => ({
  height: '100%',
}))
