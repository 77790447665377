import React from 'react'
import dynamic from 'next/dynamic'
import styled from '@mui/material/styles/styled'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'

export const WrapperIconText = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(4),
  alignItems: 'center',
}))

export const ContentIcon = styled('div')(({ theme }) => ({
  display: 'inherit',
  color: theme.palette.background.dark.primary,
  width: 24,
}))

export const ContentText = styled('div')(() => ({
  display: 'inherit',
}))

const IconWithText: React.FC<{ teasableItem: ILXTeaser }> = ({ teasableItem }) => {
  const title = teasableItem.teaserTitle1 || ''
  const icon = teasableItem.teaserIcon || ''

  return (
    <WrapperIconText>
      {
        <ContentIcon>
          <CmsIcon teaserIcon={icon} />
        </ContentIcon>
      }
      <div>{title}</div>
    </WrapperIconText>
  )
}

export default IconWithText
